import { getEnv } from '@unicaiot/unica-iot-gallery-core'
import { Config as ConfigGallery, config as configGallery } from '@unicaiot/unica-iot-gallery-airinsight'

export interface Config extends ConfigGallery {
    webshopPath: string
    mapping: (code: string) => string
    rooms: string
    aRoom: string
    subscriptions: string
    unsubscribe: (id: string) => string
    apiToken: string
    hdata: {
        get: string
    }
    hpredictions: {
        get: string
    }
    defaultLocale: string
}

export const config: Config = {
    ...configGallery,
    webshopPath: getEnv('WEBSHOP_PATH'),
    apiToken: getEnv('API_TOKEN'),
    mapping: (code: string) => `/mapping/co2/${code}`,
    rooms: '/api/rooms',
    aRoom: `/api/rooms/air`,
    subscriptions: '/api/subscriptions',
    unsubscribe: (id: string) => `/api/subscriptions/${id}/unsubscribe`,
    hdata: {
        get: '/hs/hisRead',
    },
    hpredictions: {
        get: '/hs/predictionsRead',
    },
    defaultLocale: 'nl',
}
