import { defineMessage } from '@lingui/macro'

export const translations = {
    roomView: {
        title: {
            good: defineMessage({ id: 'roomView.titleGood', message: 'De luchtkwaliteit is goed' }),
            moderate: defineMessage({ id: 'roomView.titleModerate', message: 'De luchtkwaliteit is matig' }),
            bad: defineMessage({ id: 'roomView.titlebad', message: 'Let op: de luchtkwaliteit is slecht' }),
        },
    },
    roomDetailView: {
        title: defineMessage({ id: 'roomDetailView.title', message: 'De luchtkwaliteit' }),
        tips: [
            defineMessage({ id: 'roomDetail.tip1', message: 'Indien mogelijk kun je de ventilatiestand verhogen.' }),
            defineMessage({
                id: 'roomDetail.tip2',
                message: 'Een andere optie is om ramen of deuren even open zetten.',
            }),
            defineMessage({
                id: 'roomDetail.tip3',
                message: 'Tenslotte kun je er voor kiezen om de ruimte te verlaten en later terug te komen.',
            }),
        ],
        airInsightWebsiteLink: defineMessage({
            id: 'roomDetail.airInsightWebsiteLink',
            message: 'https://www.unica.nl/',
        }),
    },
    subscribeView: {
        title: defineMessage({ id: 'subscribe.title', message: 'Altijd op de hoogte' }),
        subTitle: defineMessage({
            id: 'subscribe.subTitle',
            message: 'Ontvang een SMS als de luchtkwaliteit slecht wordt.',
        }),
    },
    subscriptionWidget: {
        phoneNumber: {
            placeholder: defineMessage({ id: 'subscribe.form.phone.placeholder', message: 'Vul uw nummer in...' }),
            numberPrefix: defineMessage({ id: 'subscribe.form.phone.numberPrefix', message: '+31' }),
        },
    },
    subscribeSuccess: {
        title: defineMessage({ id: 'subscribeSuccess.title', message: 'We houden je op de hoogte.' }),
        description: defineMessage({
            id: 'subscribeSucces.description',
            message: 'Als de luchtkwaliteit verandert in deze ruimte ontvang je van ons een SMS.',
        }),
    },
    unsubscribe: {
        successTitle: defineMessage({ id: 'unsubscribe.successTitle', message: 'Succesvol afgemeld.' }),
        successDescription: defineMessage({
            id: 'unsubscribe.successDescription',
            message:
                'Wil je weer meldingen ontvangen over de luchtkwaliteit van een ruimte? Scan dan een QR code en meld je opnieuw aan.',
        }),
    },
    ErrorLayout: {
        title: defineMessage({ id: 'ErrorLayout.title', message: 'Oeps, er is iets fout gegaan.' }),
    },
    TipsTable: {
        tips: defineMessage({ id: 'TipsTable.tips', message: 'TIPS' }),
    },
    Graph: {
        bad: defineMessage({ id: 'Graph.bad', message: 'slecht' }),
        good: defineMessage({ id: 'Graph.good', message: 'goed' }),
    },
    errorView: {
        backToDefault: defineMessage({ id: 'errorView.backToDefault', message: 'Ga terug naar de standaard pagina' }),
    },
}
