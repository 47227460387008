import React, { FunctionComponent, useContext, useState } from 'react'
import styles from './SubscriptionWidget.module.scss'
import { roomService } from '../../../Rooms/services/service'
import { Trans } from '@lingui/macro'
import dateFormat from 'dateformat'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import { translations } from '../../../../translations'
import classNames, { Argument } from 'classnames'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { Button, ButtonStyleType, Icons } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'

export interface SubscriptionWidgetProps {
    pointRef?: string
    tenantId?: string
    className?: Argument
    roomId: string
}

export const SubscriptionWidget: FunctionComponent<SubscriptionWidgetProps> = ({
    pointRef,
    tenantId,
    className,
    roomId,
}) => {
    const locale = useContext(LocaleContext)
    const history = useHistory()

    const addHours = (date: Date, hours: number) => {
        date.setHours(date.getHours() + hours)
        date.setMinutes(Math.round(date.getMinutes() / 15) * 15)
        date.setSeconds(0)
        return date
    }

    const getCombineDateAndTime = () => {
        if (time) {
            const timeParts = time.split(':')
            const editedTime = new Date(date)

            editedTime.setHours(parseInt(timeParts[0], 10))
            editedTime.setMinutes(parseInt(timeParts[1], 10))

            return editedTime
        }

        return date
    }

    const [date, setDate] = useState<Date>(addHours(new Date(), 24))
    const [time, setTime] = useState<string>('18:00')
    const [number, setNumber] = useState<string>()

    const [loading, setLoading] = useState(false)
    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            if (!(pointRef && tenantId && number)) {
                return
            }

            setLoading(true)

            const subResult = await roomService.postSubscription(tenantId, {
                pointRef: pointRef,
                phoneNumber: `31${number}`,
                finalDate: getCombineDateAndTime().toUTCString(),
            })

            setLoading(false)

            if (subResult.status === 200) {
                //redirect to success
                return history.push(routes.subscribeSuccess(roomId))
            }

            return history.push(routes.error(roomId))
        } catch (e) {
            setLoading(false)
        }
    }

    // Check if browser supports date input
    const isDateSupported = () => {
        const input = document.createElement('input')
        const value = 'a'
        input.setAttribute('type', 'date')
        input.setAttribute('value', value)
        return input.value !== value
    }

    return (
        <div className={classNames(styles.content, className)}>
            <form className={styles.form} onSubmit={handleOnSubmit}>
                <label className={styles.label}>
                    <div className={styles.labelText}>
                        <Trans id="subscriptionWidget.phoneLabel">Uw telefoonnummer</Trans>
                    </div>
                    <div className={styles.inputWrapper}>
                        <span className={styles.inputPrefix}>
                            {locale._(translations.subscriptionWidget.phoneNumber.numberPrefix)}
                        </span>
                        <input
                            className={styles.input}
                            type="tel"
                            pattern="^[1-9][0-9]{8}$"
                            // className={styles.number}
                            placeholder={locale._(translations.subscriptionWidget.phoneNumber.placeholder)}
                            onChange={e => setNumber(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className={styles.disclaimer}>
                        <Trans id="subscriptionWidget.phoneDisclaimer">
                            Wij gebruiken uw telefoonnummer uitsluitend voor meldingen over de luchtkwaliteit. U kunt
                            zich ten alle tijden afmelden via een link in de SMS.
                        </Trans>
                    </div>
                </label>

                <div className={styles.formGroup}>
                    <div className={styles.labelText}>
                        <Trans id="subscriptionWidget.timeLabel">Stel een einddatum en tijd in</Trans>
                    </div>
                    {/* Use native if supported, use fallback if not */}
                    {isDateSupported() ? (
                        <>
                            <label className={styles.label}>
                                <input
                                    className={styles.input}
                                    type="date"
                                    defaultValue={dateFormat(date, 'yyyy-mm-dd')}
                                    onChange={e => e.target.value && setDate(new Date(e.target.value))}
                                />
                            </label>
                            <label className={styles.label}>
                                <input
                                    className={styles.input}
                                    type="time"
                                    onChange={e => e.target.value && setTime(e.target.value)}
                                    defaultValue={time}
                                />
                            </label>
                        </>
                    ) : (
                        <DatePicker
                            // className={styles.dp}
                            required={true}
                            selected={date}
                            onChange={(date: Date | null) => date && setDate(date)}
                            minDate={new Date()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={15}
                            timeCaption="time"
                            showTimeSelect={true}
                            wrapperClassName={styles.wrapper}
                        />
                    )}
                </div>
                <Button
                    buttonStyle={ButtonStyleType.secondary}
                    isSubmit={true}
                    icon={Icons.notification}
                    className={styles.submitButton}
                    loading={loading}
                    // disabled={pointRef && tenantId && number ? false : true}
                >
                    <Trans id="subscriptionWidget.submitButton">Zet SMS melding aan</Trans>
                </Button>
            </form>
        </div>
    )
}
