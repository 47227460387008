import styles from './AirQualityBar.module.scss'

import React, { useContext } from 'react'

import classNames, { Argument } from 'classnames'
import { Trans } from '@lingui/macro'
import { translations } from '../../../../translations'
import { Icon, Icons, Title, FontSize, HeadingLevel, TitleColor } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { ClassCO2Prefs, AirQualityType, CO2Defaults } from '../AirQuality/AirQuality'
import { useGetTenantsWithCo2PredictionsEnabledCO2Preference } from '../../services/hooks'

interface Props {
    className?: Argument
    value?: number
    tempValue?: number
    tenant?: string
}

const MIN_VALUE = 400
const MAX_VALUE = 1600

export const AirQualityBar: React.FunctionComponent<Props> = props => {
    const getValueInPercent = () => {
        const { value } = props

        if (!value) {
            return
        }

        let difference

        // if the value is lower than 0, put it back on zero so the arrow doesn't fall off
        // if it's bigger set it the the max so it doesn't fall of on the other end
        // else, remove the min value to be able to calculate the percentage
        if (value < 0) {
            difference = 0
        } else if (value > MAX_VALUE) {
            difference = MAX_VALUE - MIN_VALUE
        } else {
            difference = value - MIN_VALUE
        }

        // Add 25 so the arrow doens't fall off at the max value
        const maxValue = MAX_VALUE - MIN_VALUE + 25

        return (difference / maxValue) * 100
    }
    const { className, value, tenant, tempValue } = props
    const locale = useContext(LocaleContext)
    const classes = classNames(styles.container, className)
    const percentage = getValueInPercent()
    let prefCO2values = new ClassCO2Prefs(CO2Defaults)
    const airQuality = checkAir(value || 0, prefCO2values)
    const prefCO2Results = useGetTenantsWithCo2PredictionsEnabledCO2Preference(tenant)
    if (prefCO2Results.data?.maxCo2LevelUI != undefined || prefCO2Results.data?.minCo2LevelUI != undefined) {
        prefCO2values = new ClassCO2Prefs({
            minCo2LevelUI: prefCO2Results.data?.minCo2LevelUI,
            maxCo2LevelUI: prefCO2Results.data?.maxCo2LevelUI,
        })
    }
    return (
        <div className={classes}>
            {!!tempValue && (
                <Title
                    fontSize={FontSize.size24}
                    headingLevel={HeadingLevel.h1}
                    color={TitleColor.light}
                    className={styles.title}
                >
                    {locale._(translations.roomView.title[airQuality])}
                </Title>
            )}

            <div className={styles.contentWrapper}>
                <div className={styles.barWrapper}>
                    {value && (
                        <div style={percentage ? { left: `${percentage}%` } : {}} className={styles.valueWrapper}>
                            <div className={styles.barValue}>
                                {value}
                                <Icon className={styles.arrow} size={16} icon={Icons.arrow} />
                            </div>
                        </div>
                    )}
                    <div className={styles.bar} />
                </div>
                <div className={styles.labelWrapper}>
                    <div className={styles.valueLabel}>
                        <span className={styles.bold}>{MIN_VALUE}</span>
                        <span>
                            <Trans id="AirQualityBar.good">goed</Trans>
                        </span>
                    </div>
                    <div className={styles.valueLabel}>
                        <span>
                            <Trans id="AirQualityBar.bad">slecht</Trans>
                        </span>
                        <span className={styles.bold}>{MAX_VALUE}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

let airQuality: AirQualityType

function checkAir(value: number, Prefs: ClassCO2Prefs) {
    if (!value || value < (Prefs.options.minCo2LevelUI || 0)) {
        airQuality = AirQualityType.good
    } else if (value >= (Prefs.options.minCo2LevelUI || 0) && value < (Prefs.options.maxCo2LevelUI || 0)) {
        airQuality = AirQualityType.moderate
    } else {
        airQuality = AirQualityType.bad
    }
    return airQuality
}
