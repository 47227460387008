import { Trans } from '@lingui/macro'
import { LinkButton, SuccessLayout } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import React, { useContext } from 'react'

import { RouteComponentProps } from 'react-router'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'

interface Props extends RouteComponentProps<{ id: string }> {}

export const SubscribeSuccessView: React.FunctionComponent<Props> = props => {
    const locale = useContext(LocaleContext)
    const id = props.match.params.id

    return (
        <SuccessLayout
            title={locale._(translations.subscribeSuccess.title)}
            description={locale._(translations.subscribeSuccess.description)}
            renderButton={() => (
                <LinkButton to={routes.roomCo2.index(id)}>
                    <Trans id="subscribeSuccess.buttonText">Terug naar de lucht</Trans>
                </LinkButton>
            )}
        />
    )
}
