import { Trans } from '@lingui/macro'
import { ErrorLayout, LinkButton } from '@unicaiot/unica-iot-gallery-airinsight'
import React from 'react'

import { RouteComponentProps } from 'react-router'
import { routes } from '../../../routes'

interface Props extends RouteComponentProps<{ id: string }> {}

export const ErrorView: React.FunctionComponent<Props> = props => {
    const id = props.match.params.id

    return (
        <ErrorLayout
            showFooter={true}
            renderButton={() => (
                <LinkButton to={routes.roomCo2.index(id)}>
                    <Trans id="ErrorView.backTo">Ga terug naar de status pagina</Trans>
                </LinkButton>
            )}
        />
    )
}
