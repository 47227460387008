import { config } from '../../../config'
import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { Mapping, Room, HData, Subscription, IPrefs } from './types'
import dateFormat from 'dateformat'

import { Preference, TenantsWithCo2PredictionsEnabledPreferenceKey } from '@unicaiot/unica-iot-gallery-airinsight'

export interface IRoomService {
    getMapping: (id: string) => ResponsePromise<Mapping>
    getRoom: (tenantId: string, pointRef: string) => ResponsePromise<Room>
    getARoom: (tenantId: string, equipRef: string) => ResponsePromise<Room>
    getData: (tenantId: string, id: string) => ResponsePromise<HData>
    getPredictions: (tenantId: string, id: string) => ResponsePromise<HData>
    postSubscription: (tenantId: string, subsciption: Subscription) => ResponsePromise
    unsubscribe: (id: string, tenantId: string) => ResponsePromise
    getTenantsWithCo2PredictionsEnabledPreference: (tenantId: string) => ResponsePromise<string[]>
    getTenantsWithCo2PredictionsEnabledCO2Preference: (tenantId: string) => ResponsePromise<IPrefs>
}

export const roomService: IRoomService = {
    getMapping(id: string) {
        return client.get<Mapping>(config.mapping(id))
    },
    getRoom(tenantId: string, pointRef: string) {
        return client.get<Room>(config.rooms, { params: { pointRef }, headers: { 'X-IoT-Unica-TenantId': tenantId } })
    },
    getARoom(tenantId: string, equipRef: string) {
        return client.get<Room>(config.aRoom, {
            params: { equipRef },
            headers: { 'X-IoT-Unica-TenantId': tenantId },
        })
    },
    getData(tenantId: string, id: string) {
        function getFormatedDate(date: Date) {
            return `${dateFormat(date, 'yyyy-mm-dd')}`
        }

        const now = new Date()
        const from = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())

        return client.get(config.hdata.get, {
            params: { id: `@${id}`, range: `"${getFormatedDate(from)}"` },
            headers: { 'X-IoT-Unica-TenantId': tenantId },
        })
    },
    getPredictions(tenantId: string, id: string) {
        return client.get(config.hpredictions.get, {
            params: { id: `@${id}` },
            headers: { 'X-IoT-Unica-TenantId': tenantId },
        })
    },
    postSubscription(tenantId: string, subsciption: Subscription) {
        return client.post(`${config.subscriptions}`, subsciption, { headers: { 'X-IoT-Unica-TenantId': tenantId } })
    },
    unsubscribe(id: string, tenantId: string) {
        return client.post(`${config.unsubscribe(id)}`, undefined, { headers: { 'X-IoT-Unica-TenantId': tenantId } })
    },
    async getTenantsWithCo2PredictionsEnabledPreference(tenantId: string) {
        const result = await client.post<Preference<string[]>>(
            config.preferences.preferencesCombined('00000000-0000-0000-0000-000000000000'),
            [TenantsWithCo2PredictionsEnabledPreferenceKey],
            {
                headers: { 'X-IoT-Unica-TenantId': tenantId },
            }
        )

        return { ...result, data: result.data?.[TenantsWithCo2PredictionsEnabledPreferenceKey] }
    },
    async getTenantsWithCo2PredictionsEnabledCO2Preference(tenantId: string) {
        const result = await client.post<Preference<IPrefs[]>>(
            config.preferences.preferencesCombined('00000000-0000-0000-0000-000000000000'),
            ['minCo2LevelUI', 'maxCo2LevelUI'],
            {
                headers: { 'X-IoT-Unica-TenantId': tenantId },
            }
        )

        return { ...result, data: result.data }
    },
}
