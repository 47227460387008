import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { config } from './config'
import { routes } from './routes'
import { RoomView } from './domain/Rooms/Views/RoomView/RoomView'
import { TelemetryProvider, RedirectorTemplate, client, Shield, useResize } from '@unicaiot/unica-iot-gallery-core'
import { UnsubscribeView } from './domain/Rooms/Views/UnsubscribeView/UnsubscribeView'
import { RoomDetailView } from './domain/Rooms/Views/RoomDetailView/RoomDetailView'
import { SubscribeView } from './domain/Subscribe/Views/SubscribeView/SubscribeView'
import { SubscribeSuccessView } from './domain/Subscribe/Views/SubscribeSuccessView/SubscribeSuccessView'
import { ErrorView } from './domain/Error/Views/ErrorView'
import { GlobalErrorView } from '@unicaiot/unica-iot-gallery-airinsight'

client.interceptors.request.use(response => {
    response.headers['Authorization'] = config.apiToken
    return response
})

export const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={params => {
                let path = null

                if (params.c) {
                    path = routes.roomCo2.index(`${params.c}`)
                }

                if (params.u) {
                    path = routes.unsubscribe(`${params.u}`)
                }

                if (path) {
                    return <Redirect to={path} />
                }

                window.location.href = config.webshopPath
                return <></>
            }}
        />
    )
}

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route exact={true} path={routes.index} component={Redirector} />
                    <Route exact={true} path={routes.roomCo2.index()} component={RoomView} />
                    <Route exact={true} path={routes.roomCo2.detail()} component={RoomDetailView} />
                    <Route exact={true} path={routes.subscribeSuccess()} component={SubscribeSuccessView} />
                    <Route exact={true} path={routes.subscribe()} component={SubscribeView} />
                    <Route exact={true} path={routes.unsubscribe()} component={UnsubscribeView} />
                    <Route path={routes.error()} component={ErrorView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
