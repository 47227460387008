import styles from './AirQualityClouds.module.scss'

import React from 'react'

import classNames, { Argument } from 'classnames'
import { AirQualityType } from '../AirQuality/AirQuality'

interface Props {
    className?: Argument
    airQuality: AirQualityType
}

export class AirQualityClouds extends React.Component<Props> {
    public render() {
        const classes = classNames(styles.container, this.props.className)

        return <div className={classes}> {this.renderClouds()} </div>
    }

    private renderClouds() {
        const { airQuality } = this.props

        if (airQuality === AirQualityType.good) {
            return this.getGoodClouds()
        }

        if (airQuality === AirQualityType.moderate) {
            return this.getModerateClouds()
        }

        if (airQuality === AirQualityType.bad) {
            return this.getBadClouds()
        }
    }

    private getGoodClouds() {
        return (
            <svg
                className={classNames(styles.cloud, styles.good)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 257 155"
                enableBackground="new 0 0 257 155"
            >
                <g>
                    <defs>
                        <filter id="a" filterUnits="userSpaceOnUse" x="0" y="-119" width="352" height="273.8">
                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                        </filter>
                    </defs>
                    <mask maskUnits="userSpaceOnUse" x="0" y="-119" width="352" height="273.8" id="b">
                        <g filter="url(#a)">
                            <path d="M0 0h257v155H0V0z" fillRule="evenodd" clipRule="evenodd" fill="#fff" />
                        </g>
                    </mask>
                    <path
                        d="M94.5-119C42.3-119 0-73.9 0-18.3S42.3 82.3 94.5 82.3h.6c6.4 41.1 40 72.4 80.4 72.4 45 0 81.5-38.9 81.5-86.8v-.5c4.4.8 8.9 1.2 13.5 1.2 45 0 81.5-38.9 81.5-86.8S315.5-105 270.5-105 189-66.1 189-18.2v.5-.7C189-73.9 146.7-119 94.5-119z"
                        opacity=".244"
                        mask="url(#b)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#fff"
                        enableBackground="new"
                    />
                </g>
            </svg>
        )
    }

    private getModerateClouds() {
        return (
            <>
                <svg
                    className={classNames(styles.cloud, styles.moderate1)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 224 240"
                    enableBackground="new 0 0 224 240"
                >
                    <g>
                        <defs>
                            <filter id="a" filterUnits="userSpaceOnUse" x="-56" y="-51" width="280" height="290.8">
                                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                            </filter>
                        </defs>
                        <mask maskUnits="userSpaceOnUse" x="-56" y="-51" width="280" height="290.8" id="b">
                            <g filter="url(#a)">
                                <path d="M0 0h224v240H0V0z" fillRule="evenodd" clipRule="evenodd" fill="#fff" />
                            </g>
                        </mask>
                        <path
                            d="M25.5-51c23.9 0 45.4 11 60.3 28.4C98.9-29.9 113.7-34 129.5-34 181.7-34 224 11.1 224 66.7s-42.3 100.7-94.5 100.7h-.6c-6.4 41.1-40 72.4-80.4 72.4-45 0-81.5-38.9-81.5-86.8 0-16.8 4.5-32.4 12.2-45.7C-42.1 91.6-56 65.4-56 35.8-56-12.1-19.5-51 25.5-51z"
                            opacity=".244"
                            mask="url(#b)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="#fff"
                            enableBackground="new"
                        />
                    </g>
                </svg>
                <svg
                    className={classNames(styles.cloud, styles.moderate2)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 577.2 248"
                >
                    <path
                        d="M550.9 248c6.5-10.9 11.8-22.7 15.9-35.3 28-86.1-14.9-177.3-95.8-203.6-58.5-19-121 1.1-161.6 46.4-9-6.7-19.2-12-30.3-15.6-46-14.9-95.2 3.3-124.2 41.7-1.6-.6-3.3-1.2-5-1.8-58.7-19-122.8 16-143 78.2-10.1 31.2-7.3 63.3 5.4 90h538.6z"
                        opacity=".244"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#fff"
                    />
                </svg>
            </>
        )
    }

    private getBadClouds() {
        return (
            <>
                <svg
                    className={classNames(styles.cloud, styles.bad1)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 340 387"
                >
                    <path
                        opacity=".244"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#FFF"
                        d="M60.3 109.3c-22.9 0-43.9 8.7-60.3 23.1v247.8c30.7-8.6 54.4-35.8 59.6-69.6h.6c52.2 0 94.5-45.1 94.5-100.7.1-55.5-42.2-100.6-94.4-100.6z"
                    />
                    <path
                        opacity=".244"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#FFF"
                        d="M0 0v229.9c14.8-9.1 27.9-21.6 38.2-37.1 31.8 12.3 67.2 8.9 97.1-7.3C202 203 276.7 172 314.9 105.7c19.4-33.6 26.3-70.8 22-105.7H0z"
                    />
                </svg>
                <svg
                    className={classNames(styles.cloud, styles.bad2)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 286 249"
                >
                    <path
                        d="M0 249h256.1c28.1-37.1 33.7-87 10.3-127.5-29.8-51.6-96.2-70-152.9-44.3C84.4 49.1 41.2 38.6 0 49.4V249z"
                        opacity=".244"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#fff"
                    />
                </svg>
                <svg
                    className={classNames(styles.cloud, styles.bad3)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 164 381"
                >
                    <path
                        opacity=".244"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#FFF"
                        d="M164 68.5c-15.3-8.3-32.7-13-51-13-61.9 0-112 53.3-112 119s50.1 119 112 119h.2c4.9 32.5 24.3 59.8 50.8 74.5V68.5z"
                    />
                    <path
                        opacity=".244"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#FFF"
                        d="M164 5.3c-24.7-8.9-52.5-2.6-69 17.8-.4.5-.9 1.1-1.3 1.6-21.2-7.7-45.1-2.2-59.4 15.4-18.2 22.5-13.7 56.4 10.1 75.7 21.3 17.2 50.8 17 69.8.6 15.5 9.6 33.5 12.3 49.7 8.4V5.3z"
                    />
                </svg>
            </>
        )
    }
}
