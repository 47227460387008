import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styles from './RoomView.module.scss'
import { useGetData, useGetMapping, useGetRoom, useGetARoom } from '../../services/hooks'
import { PageError } from '@unicaiot/unica-iot-gallery-core'
import { AirQuality } from '../../Components/AirQuality/AirQuality'

import { AirQualityBar } from '../../Components/AirQualityBar/AirQualityBar'
import { Trans } from '@lingui/macro'
import { routes } from '../../../../routes'
import { ButtonStyleType, Footer, Icons, LinkButton } from '@unicaiot/unica-iot-gallery-airinsight'
export interface Params {
    id: string
}

export const RoomView: FunctionComponent<RouteComponentProps<Params>> = props => {
    const id = props.match.params.id
    const mapRequest = useGetMapping(id)

    if (mapRequest.error) {
        throw new PageError(mapRequest.error)
    }

    const roomRequest = useGetRoom(mapRequest.data?.tenantId, mapRequest.data?.value)
    const actualRoomRequest = useGetARoom(
        mapRequest.data?.tenantId,
        roomRequest?.data?.rooms === null ? roomRequest?.data?.id : roomRequest?.data?.rooms[0]?.id
    )

    const [data, setData] = useState<{ latestValue: number }>({ latestValue: 0 })
    const [tempData, setTempData] = useState<{ latestValue: number }>({ latestValue: 0 })
    const airQualityResult = useGetData(mapRequest.data?.tenantId, mapRequest.data?.value)
    const temperaturePoint =
        actualRoomRequest?.data?.rooms === null
            ? actualRoomRequest?.data?.sensors?.find(s => s.type === 'temperature')?.id
            : actualRoomRequest?.data?.rooms[0]?.sensors?.find(s => s.type === 'temperature')?.id

    const temperatureResult = useGetData(mapRequest.data?.tenantId, temperaturePoint)
    // console.log(actualRoomRequest?.data?.rooms[0]?.name)

    useEffect(() => {
        if (airQualityResult.data) {
            const sortedRows = airQualityResult.data?.rows.sort(
                (a, b) =>
                    new Date(b.ts.substring(2).split(' ')[0]).getTime() -
                    new Date(a.ts.substring(2).split(' ')[0]).getTime()
            )

            const lastRow = sortedRows[0]

            if (lastRow) {
                setData({ latestValue: Number.parseFloat(lastRow.val.substring(2)) })
            }
        }
    }, [airQualityResult.data])

    useEffect(() => {
        if (temperatureResult.data) {
            const sortedRows = temperatureResult.data?.rows.sort(
                (a, b) =>
                    new Date(b.ts.substring(2).split(' ')[0]).getTime() -
                    new Date(a.ts.substring(2).split(' ')[0]).getTime()
            )

            const lastRow = sortedRows[0]

            if (lastRow) {
                setTempData({ latestValue: Number.parseFloat(lastRow.val.substring(2)) })
            }
        }
    }, [temperatureResult.data])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <AirQuality
                    loading={airQualityResult.loading || temperatureResult.loading}
                    roomName={
                        actualRoomRequest?.data?.rooms === null
                            ? roomRequest.data?.name
                            : actualRoomRequest?.data?.rooms[0]?.name
                    }
                    value={data?.latestValue}
                    tempValue={tempData?.latestValue}
                    hasError={!!airQualityResult.error}
                    className={styles.airQuality}
                    tenant={mapRequest.data?.tenantId}
                >
                    <LinkButton
                        to={routes.roomCo2.detail(id)}
                        leftIcon={Icons.graph}
                        buttonStyle={ButtonStyleType.secondary}
                    >
                        <Trans id="RoomView.viewDetails">Meer details over de luchtkwaliteit</Trans>
                    </LinkButton>
                    <LinkButton to={routes.subscribe(id)}>
                        <Trans id="RoomView.getUpdates">Ontvang updates van veranderingen</Trans>
                    </LinkButton>
                </AirQuality>
                {!!data?.latestValue && data?.latestValue !== 0 && (
                    <AirQualityBar
                        className={styles.bar}
                        value={data?.latestValue}
                        tempValue={tempData?.latestValue}
                        tenant={mapRequest.data?.tenantId}
                    />
                )}
            </div>
            {!tempData?.latestValue && <Footer />}
        </div>
    )
}
