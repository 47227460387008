import { routes as routesGallery } from '@unicaiot/unica-iot-gallery-core'

export const routes = {
    ...routesGallery,
    roomCo2: {
        index: (id: string | number = ':id') => `/rooms/${id}`,
        detail: (id: string | number = ':id') => `/rooms/${id}/detail`,
    },
    unsubscribe: (id: string | number = ':id') => `/unsubscribe/${id}`,
    subscribe: (id: string | number = ':id') => `/subscribe/${id}`,
    error: (id: string | number = ':id') => `/error/${id}`,
    subscribeSuccess: (id: string | number = ':id') => `/subscribe/${id}/success`,
}
