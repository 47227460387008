import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'

import { Mapping, Room, HData, IPrefs } from './types'
import { roomService } from './service'

export const useGetMapping = (id: string): RequestStatus<Mapping | undefined> => {
    const getPromise = () => (id ? roomService.getMapping(id) : undefined)

    return useRequest(getPromise, [id])
}

export const useGetRoom = (tenantId?: string, pointRef?: string): RequestStatus<Room | undefined> => {
    const getPromise = () => (tenantId && pointRef ? roomService.getRoom(tenantId, pointRef) : undefined)

    return useRequest(getPromise, [tenantId, pointRef])
}

export const useGetARoom = (tenantId?: string, equipRef?: string): RequestStatus<Room | undefined> => {
    const getPromise = () => (tenantId && equipRef ? roomService.getARoom(tenantId, equipRef) : undefined)

    return useRequest(getPromise, [tenantId, equipRef])
}

export const useGetData = (tenantId?: string, pointRef?: string): RequestStatus<HData | undefined> => {
    const getPromise = () => (pointRef && tenantId ? roomService.getData(tenantId, pointRef) : undefined)

    return useRequest(getPromise, [tenantId, pointRef])
}

export const useGetPredictions = (tenantId?: string, pointRef?: string): RequestStatus<HData | undefined> => {
    const getProsmise = () => (pointRef && tenantId ? roomService.getPredictions(tenantId, pointRef) : undefined)

    return useRequest(getProsmise, [tenantId, pointRef])
}

export const useUnsubscribe = (id: string, tenantId: string): RequestStatus<void> => {
    const getPromise = () => roomService.unsubscribe(id, tenantId)

    return useRequest(getPromise, [id, tenantId])
}

export const useGetTenantsWithCo2PredictionsEnabledPreference = (
    tenantId?: string
): RequestStatus<string[] | undefined> => {
    const getProsmise = () =>
        tenantId ? roomService.getTenantsWithCo2PredictionsEnabledPreference(tenantId) : undefined

    return useRequest(getProsmise, [tenantId])
}

export const useGetTenantsWithCo2PredictionsEnabledCO2Preference = (
    tenantId?: string
): RequestStatus<IPrefs | undefined> => {
    const getProsmise = () =>
        tenantId ? roomService.getTenantsWithCo2PredictionsEnabledCO2Preference(tenantId) : undefined

    return useRequest(getProsmise, [tenantId])
}
