import styles from './RoomDetailView.module.scss'

import { Trans } from '@lingui/macro'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'

import { RouteComponentProps } from 'react-router'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import {
    useGetData,
    useGetMapping,
    useGetPredictions,
    useGetTenantsWithCo2PredictionsEnabledPreference,
    useGetTenantsWithCo2PredictionsEnabledCO2Preference,
} from '../../services/hooks'
import {
    Title,
    ButtonStyleType,
    ErrorLayout,
    Graph,
    HeadingLevel,
    Icons,
    LinkButton,
    PageLayout,
    Paragraph,
    TipsTable,
} from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { IPrefs } from '../../services/types'
interface Params {
    id: string
}

export const RoomDetailView: FunctionComponent<RouteComponentProps<Params>> = props => {
    const locale = useContext(LocaleContext)

    const id = props.match.params.id
    const mapRequest = useGetMapping(id)

    const [data, setData] = useState<{ value: number; date: Date }[]>([])
    const [predictions, setPredictions] = useState<{ value: number; date: Date }[]>()

    const result = useGetData(mapRequest.data?.tenantId, mapRequest.data?.value)

    const prResult = useGetTenantsWithCo2PredictionsEnabledPreference(mapRequest.data?.tenantId)

    let showMessage = false

    const CO2Defaults: IPrefs = {
        minCo2LevelUI: 800,
        maxCo2LevelUI: 1200,
    }

    const prefCO2Results = useGetTenantsWithCo2PredictionsEnabledCO2Preference(mapRequest.data?.tenantId)
    if (prefCO2Results.data?.maxCo2LevelUI != undefined || prefCO2Results.data?.minCo2LevelUI != undefined) {
        if (prefCO2Results.data != CO2Defaults) {
            showMessage = true
        }
    }

    const pResult = useGetPredictions(
        mapRequest.data?.tenantId,
        mapRequest.data?.tenantId && prResult.data?.includes(mapRequest.data.tenantId)
            ? mapRequest.data?.value
            : undefined
    )

    useEffect(() => {
        if (result.data) {
            const dataset = result.data?.rows
                .map(r => {
                    return {
                        value: Number.parseFloat(r.val.substring(2)),
                        date: new Date(r.ts.substring(2).split(' ')[0]),
                    }
                })
                .sort((a, b) => b.date.getTime() - a.date.getTime())
            setData(dataset)
        }
    }, [result.data])

    useEffect(() => {
        if (pResult.data) {
            const dataset = pResult.data?.rows
                .map(r => {
                    return {
                        value: Math.round(Number.parseFloat(r.val.substring(2))),
                        date: new Date(r.ts.substring(2).split(' ')[0]),
                    }
                })
                .sort((a, b) => b.date.getTime() - a.date.getTime())
            setPredictions(dataset)
        }
    }, [pResult.data])

    if (mapRequest.error || result.error) {
        return (
            <ErrorLayout
                renderButton={() => (
                    <LinkButton to={routes.roomCo2.index(id)}>
                        <Trans id="roomDetail.returnToStatus">Ga terug naar de status pagina</Trans>
                    </LinkButton>
                )}
            />
        )
    }

    const tips = translations.roomDetailView.tips.map(tiptext => locale._(tiptext))

    return (
        <PageLayout
            title={locale._(translations.roomDetailView.title)}
            backRoute={routes.roomCo2.index(id)}
            content={() => (
                <>
                    <Graph
                        loading={mapRequest.loading || result.loading || prResult.loading || pResult.loading}
                        data={data}
                        predictions={predictions}
                    />
                    <div className={styles.information}>
                        <Title className={styles.infoTitle} headingLevel={HeadingLevel.h2}>
                            <Trans id={'roomDetail.tipsTitle'}>Hoe meer CO2, hoe slechter de kwaliteit.</Trans>
                        </Title>
                        <Paragraph>
                            <Trans id={'roomDetail.tipsDescription'}>
                                Een belangrijke indicator voor luchtkwaliteit is de CO2 concentratie. Een hoge
                                concentratie betekent dat er te weinig verse lucht aanwezig is.
                            </Trans>
                        </Paragraph>
                        {showMessage && (
                            <Paragraph>
                                <Trans id={'roomDetail.showMessage'}>
                                    In dit gebouw wijkt de Co2 grenswaarde van 1600 ppm af van de 1200 ppm RIVM-norm.
                                </Trans>
                            </Paragraph>
                        )}
                        <TipsTable className={styles.tipsTable} tips={tips} />
                    </div>
                    <div className={styles.buttons}>
                        <LinkButton
                            buttonStyle={ButtonStyleType.externalLink}
                            isExternal={true}
                            to={locale._(translations.roomDetailView.airInsightWebsiteLink)}
                            rightIcon={Icons.externallink}
                        >
                            <Trans id="roomDetail.moreInformation">Ga voor meer advies naar onze website</Trans>
                        </LinkButton>
                        <LinkButton to={routes.subscribe(id)}>
                            <Trans id="roomDetail.getUpdates">Ontvang updates van veranderingen</Trans>
                        </LinkButton>
                    </div>
                </>
            )}
        />
    )
}
