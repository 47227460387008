import styles from './AirQuality.module.scss'

import React, { useContext } from 'react'
import classNames, { Argument } from 'classnames'
import { translations } from '../../../../translations'
import { AirQualityClouds } from '../AirQualityClouds/AirQualityClouds'
import { useGetTenantsWithCo2PredictionsEnabledCO2Preference } from '../../services/hooks'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import { Trans } from '@lingui/macro'
import { IPrefs } from '../../services/types'
import {
    Title,
    Button,
    ErrorLayout,
    FontSize,
    HeadingLevel,
    TitleColor,
    BuildingInsightLogo,
} from '@unicaiot/unica-iot-gallery-airinsight'
import TempSensor from '../TempSensor/TempSensor'

interface Props {
    className?: Argument
    value?: number
    tempValue?: number
    roomName?: string
    loading: boolean
    hasError: boolean
    tenant?: string
}

export enum AirQualityType {
    good = 'good',
    moderate = 'moderate',
    bad = 'bad',
}
let airQuality: AirQualityType

export const CO2Defaults: IPrefs = {
    minCo2LevelUI: 800,
    maxCo2LevelUI: 1200,
}
export class ClassCO2Prefs {
    public options: IPrefs

    constructor(XOptions: IPrefs) {
        this.options = { ...CO2Defaults, ...XOptions }
    }
}

function checkAir(value: number, Prefs: ClassCO2Prefs) {
    if (!value || value < (Prefs.options.minCo2LevelUI || 0)) {
        airQuality = AirQualityType.good
    } else if (value >= (Prefs.options.minCo2LevelUI || 0) && value < (Prefs.options.maxCo2LevelUI || 0)) {
        airQuality = AirQualityType.moderate
    } else {
        airQuality = AirQualityType.bad
    }
    return airQuality
}

export const AirQuality: React.FunctionComponent<Props> = props => {
    const { className, value, children, roomName, loading, hasError, tenant, tempValue } = props
    const locale = useContext(LocaleContext)

    const prefCO2Results = useGetTenantsWithCo2PredictionsEnabledCO2Preference(tenant)

    let prefCO2values = new ClassCO2Prefs(CO2Defaults)

    if (prefCO2Results.data?.maxCo2LevelUI != undefined || prefCO2Results.data?.minCo2LevelUI != undefined) {
        prefCO2values = new ClassCO2Prefs({
            minCo2LevelUI: prefCO2Results.data?.minCo2LevelUI,
            maxCo2LevelUI: prefCO2Results.data?.maxCo2LevelUI,
        })
    }

    checkAir(value || 0, prefCO2values)

    if (hasError) {
        return (
            <div className={classNames(styles.container, styles.error, className)}>
                <ErrorLayout
                    renderButton={() => (
                        <Button onClick={() => window.location.reload()}>
                            <Trans id="AirQuality.refreshButton">Probeer opnieuw</Trans>
                        </Button>
                    )}
                />
            </div>
        )
    }

    if (loading) {
        return (
            <div className={classNames(styles.container, className)}>
                <ActivityIndicator className={styles.loading} size={ActivityIndicatorEnumType.large} />
            </div>
        )
    }

    if (value == 0 || !value) {
        return (
            <div className={classNames(styles.sensorErrorContainer, className)}>
                <svg
                    className={classNames(styles.buildingInsightLogo, className)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="27 39.5 490.79998779296875 71.80000305175781"
                    fill="#10264D"
                >
                    <g>
                        <path
                            d="M58.5,68.9c-0.5-2.1-1.3-3.9-2.3-5.5c-1.1-1.6-2.4-2.9-4.1-4c-1.7-1-3.7-1.5-6-1.5c-2.6,0-4.8,0.5-6.6,1.5
                c-1.8,1-3.3,2.3-4.4,4c-1.1,1.6-1.9,3.4-2.4,5.5c-0.5,2.1-0.7,4.1-0.7,6.2c0,2.1,0.2,4.2,0.7,6.2c0.5,2.1,1.3,3.9,2.4,5.5
                c1.1,1.6,2.6,2.9,4.4,4c1.8,1,4,1.5,6.6,1.5c2.3,0,4.4-0.5,6-1.5c1.7-1,3-2.3,4.1-4c1.1-1.6,1.8-3.5,2.3-5.5
                c0.5-2.1,0.7-4.1,0.7-6.2C59.3,73.1,59,71,58.5,68.9 M31.9,39.5v23.1h0.2c0.5-1.5,1.3-2.7,2.2-3.8c1-1.1,2.1-2,3.3-2.8
                c1.3-0.8,2.6-1.3,4.1-1.7c1.5-0.4,2.9-0.5,4.4-0.5c3.1,0,5.7,0.6,8,1.7c2.3,1.1,4.2,2.7,5.7,4.7c1.5,2,2.6,4.2,3.4,6.8
                c0.7,2.6,1.1,5.3,1.1,8.2c0,2.9-0.4,5.6-1.1,8.2c-0.7,2.6-1.8,4.9-3.4,6.8c-1.5,2-3.4,3.5-5.7,4.6c-2.3,1.1-4.9,1.7-8,1.7
                c-3.3,0-6.2-0.8-8.8-2.3c-2.6-1.5-4.4-3.7-5.6-6.5h-0.2v7.7H27V39.5H31.9z"
                        />
                        <path
                            d="M100,95.3v-7.3h-0.2c-1.3,2.7-3.2,4.8-5.7,6.2c-2.5,1.4-5.2,2.1-8.2,2.1c-2.6,0-4.7-0.3-6.5-1.1
                c-1.8-0.7-3.3-1.7-4.4-3c-1.1-1.3-1.9-2.9-2.5-4.8c-0.5-1.9-0.8-4-0.8-6.4V55h4.9v26.3c0.1,3.7,1,6.4,2.5,8.3
                c1.6,1.9,4.3,2.8,8.3,2.8c2.1,0,4-0.5,5.5-1.4c1.5-0.9,2.8-2.1,3.8-3.6c1-1.5,1.7-3.2,2.2-5.2c0.5-2,0.7-3.9,0.7-6V55h4.9v40.4H100
                z"
                        />
                    </g>
                    <path d="M114,95.3h4.9V55H114V95.3z M114,47.4h4.9v-7.9H114V47.4z" />
                    <rect x="128.5" y="39.5" width="4.9" height="55.8" />
                    <g>
                        <path
                            d="M147,81.4c0.5,2.1,1.3,3.9,2.3,5.5c1.1,1.6,2.4,2.9,4.1,4c1.7,1,3.7,1.5,6,1.5c2.6,0,4.8-0.5,6.6-1.5
                c1.8-1,3.3-2.3,4.4-4c1.1-1.6,1.9-3.5,2.4-5.5c0.5-2.1,0.7-4.1,0.7-6.2c0-2.1-0.2-4.2-0.7-6.2c-0.5-2.1-1.3-3.9-2.4-5.5
                c-1.1-1.6-2.6-2.9-4.4-4c-1.8-1-4-1.5-6.6-1.5c-2.3,0-4.4,0.5-6,1.5c-1.7,1-3,2.3-4.1,4c-1.1,1.6-1.8,3.4-2.3,5.5
                c-0.5,2.1-0.7,4.1-0.7,6.2C146.3,77.2,146.5,79.3,147,81.4 M174.1,95.3v-7.7h-0.2c-0.5,1.3-1.3,2.5-2.3,3.6c-1,1.1-2.2,2-3.5,2.8
                c-1.3,0.8-2.7,1.3-4.2,1.8c-1.5,0.4-3,0.6-4.4,0.6c-3.1,0-5.7-0.6-8-1.7c-2.3-1.1-4.2-2.7-5.7-4.6c-1.5-2-2.6-4.2-3.4-6.8
                c-0.7-2.6-1.1-5.3-1.1-8.2c0-2.9,0.4-5.6,1.1-8.2c0.7-2.6,1.9-4.8,3.4-6.8c1.5-2,3.4-3.5,5.7-4.7c2.3-1.1,4.9-1.7,8-1.7
                c1.5,0,3,0.2,4.4,0.5c1.4,0.4,2.8,0.9,4,1.7c1.2,0.8,2.4,1.7,3.3,2.8c1,1.1,1.7,2.4,2.2,3.8h0.2V39.5h4.9v55.8H174.1z"
                        />
                    </g>
                    <path d="M188.1,95.3h4.9V55h-4.9V95.3z M188.1,47.4h4.9v-7.9h-4.9V47.4z" />
                    <g>
                        <path
                            d="M207.4,55v7h0.2c0.9-2.5,2.6-4.4,5-5.9c2.4-1.5,5-2.2,7.9-2.2c2.8,0,5.2,0.4,7.1,1.1c1.9,0.7,3.4,1.8,4.6,3.1
                c1.1,1.3,2,3,2.4,4.9c0.5,1.9,0.7,4.1,0.7,6.5v26h-4.9V70.1c0-1.7-0.2-3.3-0.5-4.8c-0.3-1.5-0.9-2.8-1.6-3.9
                c-0.8-1.1-1.8-2-3.2-2.6c-1.3-0.6-3-0.9-5-0.9c-2,0-3.7,0.4-5.3,1.1c-1.5,0.7-2.8,1.7-3.9,2.9c-1.1,1.2-1.9,2.7-2.5,4.4
                c-0.6,1.7-0.9,3.5-1,5.5v23.5h-4.9V55H207.4z"
                        />
                        <path
                            d="M266.5,89.3c1.6-0.9,3-2.1,4.1-3.6c1.1-1.5,1.9-3.2,2.5-5.1c0.5-1.9,0.8-3.9,0.8-5.9c0-2-0.2-4-0.7-5.9
                c-0.5-2-1.2-3.8-2.3-5.4c-1-1.6-2.4-2.9-4-3.9c-1.6-1-3.6-1.5-6-1.5c-2.3,0-4.4,0.5-6,1.4c-1.7,1-3,2.2-4.1,3.8
                c-1.1,1.6-1.9,3.4-2.4,5.4c-0.5,2-0.7,4.1-0.7,6.1c0,2,0.3,4,0.8,5.9c0.5,1.9,1.3,3.6,2.4,5.1c1.1,1.5,2.5,2.7,4.1,3.6
                c1.7,0.9,3.6,1.4,5.9,1.4C263,90.7,264.9,90.3,266.5,89.3 M277.8,100.1c-0.6,2.4-1.7,4.4-3.1,6.1c-1.4,1.7-3.2,2.9-5.5,3.8
                c-2.3,0.9-5,1.3-8.3,1.3c-2,0-4-0.2-5.9-0.7c-1.9-0.5-3.7-1.2-5.2-2.2c-1.5-1-2.8-2.2-3.8-3.8c-1-1.5-1.6-3.4-1.8-5.5h4.9
                c0.3,1.5,0.8,2.8,1.5,3.8c0.8,1,1.7,1.8,2.7,2.5c1.1,0.6,2.3,1.1,3.6,1.4c1.3,0.3,2.6,0.4,4,0.4c4.6,0,7.9-1.3,9.9-3.9
                c2-2.6,3-6.4,3-11.3v-5.5h-0.2c-1.1,2.5-2.8,4.5-5,6c-2.2,1.5-4.8,2.3-7.8,2.3c-3.2,0-6-0.5-8.3-1.6c-2.3-1.1-4.2-2.5-5.7-4.4
                c-1.5-1.9-2.6-4.1-3.2-6.6c-0.7-2.5-1-5.2-1-8.1c0-2.8,0.4-5.4,1.2-7.9c0.8-2.5,2-4.6,3.5-6.5c1.5-1.8,3.4-3.3,5.7-4.4
                c2.3-1.1,4.9-1.6,7.8-1.6c1.5,0,2.9,0.2,4.3,0.6c1.3,0.4,2.5,1,3.6,1.8c1.1,0.8,2.1,1.6,2.9,2.6c0.9,1,1.5,2,2,3.1h0.2v-7h4.9V92
                C278.8,95,278.5,97.7,277.8,100.1"
                        />
                    </g>
                    <path d="M307.5,95.3h4.9V55h-4.9V95.3z M307.5,47.4h4.9v-7.9h-4.9V47.4z" />
                    <g>
                        <path
                            d="M326.8,55v7h0.2c0.9-2.5,2.6-4.4,5-5.9c2.4-1.5,5-2.2,7.9-2.2c2.8,0,5.2,0.4,7.1,1.1c1.9,0.7,3.4,1.8,4.6,3.1
                c1.1,1.3,1.9,3,2.4,4.9c0.5,1.9,0.7,4.1,0.7,6.5v26h-4.9V70.1c0-1.7-0.2-3.3-0.5-4.8c-0.3-1.5-0.9-2.8-1.6-3.9
                c-0.8-1.1-1.8-2-3.2-2.6c-1.3-0.6-3-0.9-5-0.9c-2,0-3.7,0.4-5.3,1.1c-1.5,0.7-2.8,1.7-3.9,2.9c-1.1,1.2-1.9,2.7-2.5,4.4
                c-0.6,1.7-0.9,3.5-1,5.5v23.5h-4.9V55H326.8z"
                        />
                        <path
                            d="M387.2,62.8c-0.6-1.1-1.3-2-2.3-2.8c-1-0.7-2.1-1.3-3.3-1.6c-1.3-0.3-2.6-0.5-4-0.5c-1.1,0-2.2,0.1-3.3,0.3
                c-1.1,0.2-2.1,0.6-3.1,1.2c-0.9,0.5-1.7,1.3-2.3,2.1c-0.6,0.9-0.9,2-0.9,3.3c0,1.1,0.3,2,0.8,2.8c0.5,0.8,1.2,1.4,2.1,1.9
                c0.8,0.5,1.7,0.9,2.7,1.3c1,0.3,1.9,0.6,2.7,0.8l6.6,1.5c1.4,0.2,2.8,0.6,4.2,1.1c1.4,0.5,2.6,1.3,3.7,2.1c1.1,0.9,1.9,2,2.6,3.3
                c0.7,1.3,1,2.9,1,4.6c0,2.2-0.5,4.1-1.5,5.6c-1,1.5-2.3,2.8-3.8,3.8c-1.5,1-3.3,1.7-5.2,2.1c-1.9,0.4-3.8,0.6-5.7,0.6
                c-4.7,0-8.6-1.1-11.5-3.4c-3-2.2-4.6-5.8-5-10.8h4.9c0.2,3.3,1.4,5.9,3.6,7.5c2.2,1.7,5,2.5,8.2,2.5c1.2,0,2.4-0.1,3.7-0.4
                c1.3-0.3,2.5-0.7,3.6-1.3c1.1-0.6,2-1.4,2.7-2.4c0.7-1,1.1-2.1,1.1-3.5c0-1.2-0.3-2.2-0.7-3c-0.5-0.8-1.2-1.5-2-2s-1.8-1-2.8-1.4
                c-1.1-0.4-2.2-0.7-3.3-0.9l-6.3-1.4c-1.6-0.4-3.1-0.9-4.4-1.4c-1.4-0.5-2.5-1.2-3.6-2c-1-0.8-1.8-1.8-2.4-3
                c-0.6-1.2-0.9-2.6-0.9-4.3c0-2,0.5-3.8,1.4-5.2c0.9-1.5,2.1-2.6,3.6-3.5c1.5-0.9,3.1-1.5,4.9-2c1.8-0.4,3.6-0.6,5.4-0.6
                c2,0,3.9,0.3,5.7,0.8c1.7,0.5,3.3,1.3,4.6,2.4c1.3,1.1,2.4,2.5,3.2,4.1c0.8,1.6,1.2,3.5,1.3,5.7h-4.9
                C388.1,65.3,387.8,64,387.2,62.8"
                        />
                    </g>
                    <path d="M401.9,95.3h4.9V55h-4.9V95.3z M401.9,47.4h4.9v-7.9h-4.9V47.4z" />
                    <g>
                        <path
                            d="M438.4,89.3c1.6-0.9,3-2.1,4.1-3.6c1.1-1.5,1.9-3.2,2.5-5.1c0.5-1.9,0.8-3.9,0.8-5.9c0-2-0.2-4-0.7-5.9
                c-0.5-2-1.2-3.8-2.3-5.4c-1-1.6-2.4-2.9-4-3.9c-1.6-1-3.6-1.5-6-1.5c-2.3,0-4.4,0.5-6,1.4c-1.7,1-3.1,2.2-4.1,3.8
                c-1.1,1.6-1.9,3.4-2.4,5.4c-0.5,2-0.7,4.1-0.7,6.1c0,2,0.3,4,0.8,5.9c0.5,1.9,1.3,3.6,2.4,5.1c1.1,1.5,2.5,2.7,4.1,3.6
                c1.7,0.9,3.7,1.4,5.9,1.4C434.9,90.7,436.7,90.3,438.4,89.3 M449.7,100.1c-0.7,2.4-1.7,4.4-3.1,6.1c-1.4,1.7-3.2,2.9-5.5,3.8
                c-2.3,0.9-5,1.3-8.3,1.3c-2,0-4-0.2-5.9-0.7c-1.9-0.5-3.7-1.2-5.2-2.2c-1.5-1-2.8-2.2-3.8-3.8c-1-1.5-1.6-3.4-1.8-5.5h4.9
                c0.3,1.5,0.8,2.8,1.5,3.8c0.8,1,1.7,1.8,2.7,2.5c1.1,0.6,2.2,1.1,3.6,1.4c1.3,0.3,2.6,0.4,4,0.4c4.6,0,7.9-1.3,9.9-3.9
                c2-2.6,3-6.4,3-11.3v-5.5h-0.2c-1.1,2.5-2.8,4.5-5,6c-2.2,1.5-4.8,2.3-7.8,2.3c-3.2,0-6-0.5-8.3-1.6c-2.3-1.1-4.2-2.5-5.7-4.4
                c-1.5-1.9-2.6-4.1-3.3-6.6c-0.7-2.5-1-5.2-1-8.1c0-2.8,0.4-5.4,1.2-7.9c0.8-2.5,2-4.6,3.5-6.5c1.5-1.8,3.4-3.3,5.7-4.4
                c2.3-1.1,4.9-1.6,7.8-1.6c1.5,0,2.9,0.2,4.3,0.6c1.3,0.4,2.5,1,3.6,1.8c1.1,0.8,2.1,1.6,2.9,2.6c0.9,1,1.5,2,2,3.1h0.2v-7h4.9V92
                C450.6,95,450.3,97.7,449.7,100.1"
                        />
                        <path
                            d="M465,39.5v22.4h0.2c0.9-2.5,2.6-4.4,5-5.9c2.4-1.5,5-2.2,7.9-2.2c2.8,0,5.2,0.4,7.1,1.1
                c1.9,0.7,3.4,1.8,4.6,3.1c1.1,1.3,2,3,2.4,4.9c0.5,1.9,0.7,4.1,0.7,6.5v26h-4.9V70.1c0-1.7-0.2-3.3-0.5-4.8
                c-0.3-1.5-0.9-2.8-1.6-3.9c-0.8-1.1-1.8-2-3.2-2.6c-1.3-0.6-3-0.9-5-0.9c-2,0-3.7,0.3-5.3,1.1c-1.5,0.7-2.8,1.7-3.9,2.9
                c-1.1,1.2-1.9,2.7-2.5,4.4c-0.6,1.7-0.9,3.5-1,5.5v23.5h-4.9V39.5H465z"
                        />
                        <path
                            d="M517.8,55v4.1h-8.2v27.2c0,1.6,0.2,2.9,0.7,3.8c0.4,0.9,1.5,1.4,3.3,1.5c1.4,0,2.8-0.1,4.2-0.2v4.1
                c-0.7,0-1.5,0-2.2,0.1c-0.7,0.1-1.5,0.1-2.2,0.1c-3.3,0-5.6-0.6-6.9-1.9c-1.3-1.3-1.9-3.6-1.9-7.1V59.1h-7V55h7V42.9h4.9V55H517.8z
                "
                        />
                    </g>
                </svg>
                <h1 className={classNames(styles.errorTitle, className)}>Sorry, er ging iets mis</h1>
                <div className={classNames(styles.buttonWrapper, className)}>
                    <a
                        className={classNames(styles.errorLink, className)}
                        href="https://www.building-insight.nl/contact"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Neem contact met ons op
                    </a>
                </div>
                <svg
                    className={classNames(styles.errorBottomCloud, className)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 595 204"
                    enableBackground="new 0 0 595 204"
                >
                    <g transform="translate(.592)">
                        <defs>
                            <filter id="a" filterUnits="userSpaceOnUse" x="1.1" y=".3" width="253.8" height="173.3">
                                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                            </filter>
                        </defs>
                        <mask maskUnits="userSpaceOnUse" x="1.1" y=".3" width="253.8" height="173.3" id="b">
                            <g filter="url(#a)">
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFF" d="M.4 0h593v204H.4z" />
                            </g>
                        </mask>
                        <linearGradient
                            id="c"
                            gradientUnits="userSpaceOnUse"
                            x1="-7.058"
                            y1="498.216"
                            x2="-7.058"
                            y2="497.232"
                            gradientTransform="matrix(141.5962 174.8568 176.5857 -142.9963 -86764.672 72498.195)"
                        >
                            <stop offset="0" stopColor="#ffd291" />
                            <stop offset="1" stopColor="#9dc6f9" />
                        </linearGradient>
                        <path
                            mask="url(#b)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="url(#c)"
                            d="M239.6 53c24.9 30.7 18.7 76.8-13.7 103.1-29.1 23.6-69.5 23.1-95.4.5-37.1 23.3-85.3 17.4-111.9-15.4C-10.2 105.6-2.8 51.7 35.2 21s92.2-26.8 121 8.8c.7.8 1.3 1.6 1.9 2.4C187 21.4 220 28.8 239.6 53z"
                        />
                    </g>
                    <g transform="translate(.592)">
                        <defs>
                            <filter id="d" filterUnits="userSpaceOnUse" x="19.6" y="66" width="574.7" height="326.2">
                                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                            </filter>
                        </defs>
                        <mask maskUnits="userSpaceOnUse" x="19.6" y="66" width="574.7" height="326.2" id="e">
                            <g filter="url(#d)">
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FFF" d="M.4 0h593v204H.4z" />
                            </g>
                        </mask>
                        <linearGradient
                            id="f"
                            gradientUnits="userSpaceOnUse"
                            x1="-8.271"
                            y1="498.267"
                            x2="-8.271"
                            y2="497.321"
                            gradientTransform="matrix(-522.5831 -169.7975 124.6952 -383.7725 -66084.586 189871.344)"
                        >
                            <stop offset="0" stopColor="#2a4364" />
                            <stop offset="1" stopColor="#ffd291" />
                        </linearGradient>
                        <path
                            mask="url(#e)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="url(#f)"
                            d="M489.9 73.2c-58.5-19-121 1.1-161.6 46.4-9-6.7-19.2-12-30.3-15.6-46-14.9-95.2 3.3-124.2 41.7-1.6-.6-3.3-1.2-5-1.8C110 124.8 45.9 159.8 25.7 222s11.1 128.2 69.9 147.3c45.7 14.9 94.6-3 123.6-40.9 1.7.7 3.4 1.3 5.2 1.8 28.8 9.4 58.9 5.7 84.5-7.8 18.1 28.9 45.5 51.5 79.6 62.6 80.9 26.3 169.2-22.2 197.1-108.4S570.8 99.5 489.9 73.2z"
                        />
                    </g>
                </svg>
                <svg
                    className={classNames(styles.errorTopCloud, className)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 255.4 155"
                >
                    <linearGradient id="z" x1="127.716" x2="127.716" y1="0" y2="154.77" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#dfceb3" />
                        <stop offset="1" stopColor="#9dc6f9" />
                    </linearGradient>
                    <path
                        fill="url(#z)"
                        fillRule="evenodd"
                        d="M255.4 67.4V0H0c8.1 46.8 46.6 82.3 92.9 82.3h.6c6.4 41.1 40 72.4 80.4 72.4 45 0 81.5-38.9 81.5-86.8v-.5z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
        )
    }

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.good]: airQuality === AirQualityType.good,
                [styles.moderate]: airQuality === AirQualityType.moderate,
                [styles.bad]: airQuality === AirQualityType.bad,
            })}
        >
            <BuildingInsightLogo className={styles.logo} />

            {roomName && !tempValue && (
                <Title
                    fontSize={FontSize.size24}
                    headingLevel={HeadingLevel.h2}
                    color={TitleColor.light}
                    className={styles.roomName}
                >
                    {roomName}
                </Title>
            )}

            {!tempValue && (
                <Title
                    fontSize={FontSize.size44}
                    headingLevel={HeadingLevel.h1}
                    color={TitleColor.light}
                    className={styles.title}
                >
                    {locale._(translations.roomView.title[airQuality])}
                </Title>
            )}
            {!!tempValue && <TempSensor room={roomName} temp={tempValue} />}
            <div className={styles.content}>{children}</div>

            <AirQualityClouds airQuality={airQuality} />
        </div>
    )
}
