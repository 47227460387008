import React, { useContext } from 'react'

import { RouteComponentProps } from 'react-router'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import { SubscriptionWidget } from '../../Components/SubscriptionWidget/SubscriptionWidget'
import { useGetMapping } from '../../../Rooms/services/hooks'
import styles from './SubscribeView.module.scss'
import { LayoutType, PageLayout } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'

interface Props extends RouteComponentProps<Params> {}

interface Params {
    id: string
}

export const SubscribeView: React.FunctionComponent<Props> = props => {
    const locale = useContext(LocaleContext)
    const id = props.match.params.id

    const mapRequest = useGetMapping(id)

    return (
        <PageLayout
            pageType={LayoutType.dark}
            title={locale._(translations.subscribeView.title)}
            subTitle={locale._(translations.subscribeView.subTitle)}
            backRoute={routes.roomCo2.index(id)}
            content={() => (
                <SubscriptionWidget
                    className={styles.subscriptionWidget}
                    tenantId={mapRequest.data?.tenantId}
                    pointRef={mapRequest.data?.value}
                    roomId={id}
                />
            )}
        />
    )
}
