/*eslint-disable*/ export const messages = {
    'AirQuality.refreshButton': 'Probeer opnieuw',
    'AirQualityBar.bad': 'slecht',
    'AirQualityBar.good': 'goed',
    'ErrorLayout.title': 'Oeps, er is iets fout gegaan.',
    'ErrorView.backTo': 'Ga terug naar de status pagina',
    'Graph.bad': 'slecht',
    'Graph.good': 'goed',
    'RoomView.getUpdates': 'Ontvang updates van veranderingen',
    'RoomView.viewDetails': 'Meer details over de luchtkwaliteit',
    'TipsTable.tips': 'TIPS',
    'errorView.backToDefault': 'Ga terug naar de standaard pagina',
    'roomDetail.airInsightWebsiteLink': 'https://www.unica.nl/',
    'roomDetail.getUpdates': 'Ontvang updates van veranderingen',
    'roomDetail.moreInformation': 'Ga voor meer advies naar onze website',
    'roomDetail.returnToStatus': 'Ga terug naar de status pagina',
    'roomDetail.showMessage': 'In dit gebouw wijkt de Co2 grenswaarde van 1600 ppm af van de 1200 ppm RIVM-norm.',
    'roomDetail.tip1': 'Indien mogelijk kun je de ventilatiestand verhogen.',
    'roomDetail.tip2': 'Een andere optie is om ramen of deuren even open zetten.',
    'roomDetail.tip3': 'Tenslotte kun je er voor kiezen om de ruimte te verlaten en later terug te komen.',
    'roomDetail.tipsDescription':
        'Een belangrijke indicator voor luchtkwaliteit is de CO2 concentratie. Een hoge concentratie betekent dat er te weinig verse lucht aanwezig is.',
    'roomDetail.tipsTitle': 'Hoe meer CO2, hoe slechter de kwaliteit.',
    'roomDetailView.title': 'De luchtkwaliteit',
    'roomView.titleGood': 'De luchtkwaliteit is goed',
    'roomView.titleModerate': 'De luchtkwaliteit is matig',
    'roomView.titlebad': 'Let op: de luchtkwaliteit is slecht',
    'subscribe.form.phone.numberPrefix': '+31',
    'subscribe.form.phone.placeholder': 'Vul uw nummer in...',
    'subscribe.subTitle': 'Ontvang een SMS als de luchtkwaliteit slecht wordt.',
    'subscribe.title': 'Altijd op de hoogte',
    'subscribeSucces.description': 'Als de luchtkwaliteit verandert in deze ruimte ontvang je van ons een SMS.',
    'subscribeSuccess.buttonText': 'Terug naar de lucht',
    'subscribeSuccess.title': 'We houden je op de hoogte.',
    'subscriptionWidget.phoneDisclaimer':
        'Wij gebruiken uw telefoonnummer uitsluitend voor meldingen over de luchtkwaliteit. U kunt zich ten alle tijden afmelden via een link in de SMS.',
    'subscriptionWidget.phoneLabel': 'Uw telefoonnummer',
    'subscriptionWidget.submitButton': 'Zet SMS melding aan',
    'subscriptionWidget.timeLabel': 'Stel een einddatum en tijd in',
    'unsubscribe.errorRefresh': 'Probeer opnieuw',
    'unsubscribe.showStatus': 'Bekijk de status van de ruimte',
    'unsubscribe.successDescription':
        'Wil je weer meldingen ontvangen over de luchtkwaliteit van een ruimte? Scan dan een QR code en meld je opnieuw aan.',
    'unsubscribe.successTitle': 'Succesvol afgemeld.',
}
