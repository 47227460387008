import React, { FunctionComponent, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useUnsubscribe } from '../../services/hooks'
import { Trans } from '@lingui/macro'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import { Button, ErrorLayout, LinkButton, SuccessLayout } from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'

export interface Params {
    id: string
}

export const UnsubscribeView: FunctionComponent<RouteComponentProps<Params>> = props => {
    const locale = useContext(LocaleContext)
    const id = props.match.params.id.substring(0, 36)
    const tenantId = props.match.params.id.substring(37)

    const request = useUnsubscribe(id, tenantId)

    if (request.error) {
        return (
            <ErrorLayout
                showFooter={true}
                renderButton={() => (
                    <Button onClick={() => window.location.reload()}>
                        <Trans id="unsubscribe.errorRefresh">Probeer opnieuw</Trans>
                    </Button>
                )}
            />
        )
    }

    return (
        <SuccessLayout
            title={locale._(translations.unsubscribe.successTitle)}
            description={locale._(translations.unsubscribe.successDescription)}
            renderButton={() => (
                <LinkButton to={routes.roomCo2.index(id)}>
                    <Trans id={'unsubscribe.showStatus'}>Bekijk de status van de ruimte</Trans>
                </LinkButton>
            )}
        />
    )
}
